import { getUserSession } from '@reibus/frontend-utility';
import { LotSchemaStringBasedWithBackendValidation } from '@reibus/ingest-listings-api-client-axios';

export const safeParseAsync = async (companyId: string, recordData) => {
  const userSession = await getUserSession();
  const listingsSchemaWithBackendValidation =
    LotSchemaStringBasedWithBackendValidation({
      environment: process.env.ENVIRONMENT,
      token: userSession?.identityToken,
      companyId,
    });

  const result =
    await listingsSchemaWithBackendValidation.safeParseAsync(recordData);
  return result;
};
