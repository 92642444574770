import { getUserSession } from '@reibus/frontend-utility';
import uploadClient from '@reibus/ingest-listings-api-client-axios';

export const upload = async (companyId: string, lots) => {
  const userSession = await getUserSession();
  const listingsUploadClient = uploadClient({
    environment: process.env.ENVIRONMENT,
    token: userSession?.identityToken,
  });

  await listingsUploadClient.upload({
    parameters: {
      companyId,
    },
    body: {
      type: 'string-based',
      lots,
    },
  });
};
